@import "~react-image-gallery/styles/css/image-gallery.css";

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background: url('/public/bg.png') no-repeat center center fixed;
  background-size: cover;
}

body{
  padding: 0 !important;
}

.App-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Black overlay with 50% opacity */
  z-index: 1; /* Make sure the overlay is on top of the background but below the content */
}

.legal-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: white; /* Ensure text color contrasts with the background */
  z-index: 2;
}

.App-header,
.App-body,
.App-footer {
  position: relative;
  padding: 10px;
  z-index: 2;
}

.App-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  text-align: center;
}

.Title-container {
  text-align: center;
}

.App-title {
  font-size: 3rem;
  margin: 0;
}

.App-subtitle {
  font-size: 1rem;
  margin: 0;
}

.App-body {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 2;
  width: 100%;
  text-align: center;
}

.image-gallery-image {
  width: auto; /* Maintain aspect ratio */
  height: 60vh; /* Adjust the height as needed */
  object-fit: contain; /* Ensure the image covers the area */
}

.image-gallery-thumbnail {
  width: 10vw; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  top: 50%;
  height: 100%;
}

a{
  color: orange;
}

.App-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex: 1;
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 0;
  color: white;
}

.App-footer a {
  color: white;
  text-decoration: none; /* Optional: Entfernt die Unterstreichung */
}

.App-button img {
  height: 6vw; /* Adjust the height of the button */
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .App-button img {
    height: 15vw; /* Adjust the height for mobile devices */
  }
}
